import { RECAPTCHA_V2_PROPERTY_NAME } from '@m/const'
import { getContactFormSchema } from '@m/validation'
import { useIntlContext } from '@src/components/contexts/Locale'
import '@src/theme/contact.sass'
import { Field, FieldProps, Form, Formik } from 'formik'
import { navigateTo } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

interface MyFormValues {
  body: string
  email: string
  name: string
}

type ContactScreenProps = {}

export const ContactScreen: React.FC<ContactScreenProps> = () => {
  const { locale } = useIntlContext()
  // console.debug('[FormPage]', { locale })
  const recaptchaKey = process.env.GATSBY_RECAPTCHA_KEY!
  const formUrl = process.env.GATSBY_FORM_URL!
  // if (process.env.NODE_ENV === 'development') console.log('process.env', process.env)
  // console.debug({ recaptchaKey, formUrl })

  const initialValues: MyFormValues = { name: '', email: '', body: '' }
  const [recaptcha, setRecaptcha] = useState<boolean>(false)
  useEffect(() => {
    setRecaptcha(true)
  }, [])

  return (
    <div className="contactPage">
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={getContactFormSchema()}
        onSubmit={(values, actions) => {
          console.log({ values, actions })
          actions.setValues
          // alert(JSON.stringify(values, null, 2))
          fetch(formUrl, {
            method: 'POST',
            body: JSON.stringify({
              ...values,
              langCode: locale,
            }),
            headers: {
              'Content-Type': 'application/json',
              // x: 'cors',
            },
            // mode: 'no-cors',
          })
            .then((res) => {
              // const { ok, status, statusText, headers, type, url } = res
              // console.log({ ok, status, statusText, headers, type, url })
              // alert(JSON.stringify({ 'res.ok': res.ok }, null, 2))
              if (res.ok) {
                navigateTo('/thanks')
                actions.setSubmitting(false)
                return res.json()
              } else {
                return res.text()
              }
            })
            .then((res) => {
              console.log({ res })
            })
            .catch((e) => {
              console.error(e)
              actions.setSubmitting(false)
            })
        }}
        render={(formikBag) => (
          <Form
            method="POST"
            className="contact-form validate-form"
            // action="/hello"
            // action="http://localhost:5001/plusuno/us-central1/helloWorld"
            // name="contact"
          >
            <Field name="name" className="input100" render={MyTextInput} />
            <Field name="email" className="input100" render={MyTextInput} />
            <Field name="body" className="input100" render={MyTextArea} />
            <div style={{ marginBottom: '25px', alignItems: 'center' }}>
              {recaptcha && (
                <ReCAPTCHA
                  sitekey={recaptchaKey}
                  // onChange={handleRecaptcha}
                  onChange={(token) => formikBag.setFieldValue(RECAPTCHA_V2_PROPERTY_NAME, token)}
                />
              )}
            </div>
            <div className="container-contact-form-btn">
              <button
                type="submit"
                disabled={formikBag.isSubmitting || !formikBag.isValid}
                className="contact-form-btn"
              >
                SEND
              </button>
            </div>
          </Form>
        )}
      />
    </div>
  )
}

// export const pageQuery = graphql`
//   query FormQuery {
//     allServicesYaml(filter: { locale: { eq: "ja" } }) {
//       edges {
//         node {
//           icon {
//             publicURL
//           }
//           title
//           description
//         }
//       }
//     }
//   }
// `

const nameLabelMap: { [key in keyof MyFormValues]: string } = {
  name: 'Name',
  email: 'Email',
  body: 'Message',
}

const namePlaceholderMap: { [key in keyof MyFormValues]: string } = {
  name: 'your name',
  email: 'your-email@domain.com',
  body: 'Anything...',
}

const MyTextInput: React.FC<FieldProps<string>> = ({ field, meta: { touched, error } }) => (
  <div className="wrap-input100 rs1-wrap-input100 validate-input">
    <span className="label-input100">{nameLabelMap[field.name as 'name']}</span>
    <input {...field} placeholder={namePlaceholderMap[field.name as 'name']} className="input100" />
    <span className="focus-input100" />
    {touched && error && <ErrorText text={error} />}
  </div>
)
const MyTextArea: React.FC<FieldProps<string>> = ({ field, meta: { touched, error } }) => (
  <div className="wrap-input100 validate-input">
    <span className="label-input100">{nameLabelMap[field.name as 'name']}</span>
    <textarea
      {...field}
      placeholder={namePlaceholderMap[field.name as 'name']}
      className="input100"
    />
    <span className="focus-input100" />
    {touched && error && <ErrorText text={error} />}
  </div>
)

const ErrorText: React.FC<any> = (props) => (
  <span
    style={{
      color: 'tomato',
    }}
  >
    {props.text}
  </span>
)
