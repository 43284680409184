import {
  MIN_LENGTH_FORM_BODY,
  MAX_LENGTH_FORM_BODY,
  MAX_LENGTH_FORM_EMAIL,
  MIN_LENGTH_FORM_NAME,
  MAX_LENGTH_FORM_NAME,
  RECAPTCHA_V2_PROPERTY_NAME,
} from '@m/const'
import * as Yup from 'yup'

const getEmailValidation = () =>
  Yup.string()
    .max(MAX_LENGTH_FORM_EMAIL)
    .email()
    .required()

const getNameValidation = () =>
  Yup.string()
    .min(MIN_LENGTH_FORM_NAME)
    .max(MAX_LENGTH_FORM_NAME)
    .matches(/^[^\^+=<>?÷¿/*&#@!]+$/, 'Invalid characters')
    // .matches(/^[^\^+=<>?÷¿/*&#@!]+$/, t('custom.must-be-only-letters-numbers-periods-underscores'))
    .required()

const getBodyValidation = () =>
  Yup.string()
    .min(MIN_LENGTH_FORM_BODY)
    .max(MAX_LENGTH_FORM_BODY)
    .required()

export const getContactFormSchema = () =>
  Yup.object({
    email: getEmailValidation(),
    name: getNameValidation(),
    body: getBodyValidation(),
    [RECAPTCHA_V2_PROPERTY_NAME]: Yup.string().required(),
  }).noUnknown(true)
